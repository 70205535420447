<template>
    <div class="confirmacao-email">
        <v-form :readonly="loading">
            <v-container dense>
                <v-row
                    dense
                    justify="center"
                    justify-sm="start"
                >
                    <v-col cols="auto">
                        <div class="header">
                            <span class="text-h4 font-weight-bold">Confirmação de Email</span>
                            <p class="text-h6 font-weight-light grey--text text--darken-2">
                                Insira abaixo o token enviado para seu email
                                {{ tokenConfirmed }}
                            </p>
                        </div>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            v-model="token"
                            :filled="tokenConfirmed"
                            :disabled="tokenConfirmed"
                            rounded
                            outlined
                            flat
                            required
                            label="Token de confirmação"
                        />
                    </v-col>
                </v-row>
                <v-row
                    dense
                    class="mb-5"
                    justify="center"
                    justify-sm="start"
                >
                    <transition
                        mode="out-in"
                        name="left-join"
                    >
                        <v-col
                            v-if="tokenTimer !== '0:00'"
                            cols="auto"
                        >
                            <p class="grey--text">
                                Tempo para re-envio de Token: {{ tokenTimer }}
                            </p>
                        </v-col>
                        <v-col
                            v-else
                            cols="auto"
                        >
                            <v-row dense>
                                <v-col
                                    cols="auto"
                                    align-self="center"
                                >
                                    <span class="mr-2 ml-4 ml-md-0">Não recebeu o email?</span>
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn
                                        id="re-enviar"
                                        text
                                        height="32px"
                                        class="font-weight-light text-body-1"
                                        color="primary"
                                        :loading="resendingToken"
                                        :filled="resendingToken"
                                        :disabled="tokenConfirmed"
                                        @click="regenerateToken()"
                                    >
                                        Clique aqui para re-enviar
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </transition>
                </v-row>
                <v-row
                    slot="buttons"
                    dense
                    justify-sm="space-between"
                    justify="center"
                >
                    <v-col
                        cols="auto"
                        sm="auto"
                    >
                        <v-btn
                            rounded
                            filled
                            outlined
                            height="62px"
                            width="162px"
                            class="text-capitalize font-weight-light text-h6"
                            elevation="3"
                            x-large
                            :disabled="loading || resendingToken"
                            @click.prevent="returnStep()"
                        >
                            <v-icon left>
                                mdi-arrow-left
                            </v-icon>
                            Voltar
                        </v-btn>
                    </v-col>
                    <v-col
                        cols="auto"
                        offset-sm="0"
                    >
                        <v-btn
                            rounded
                            filled
                            color="primary"
                            height="62px"
                            width="162px"
                            class="text-capitalize font-weight-light text-h6"
                            elevation="3"
                            x-large
                            :disabled="$v.$invalid || loading || resendingToken"
                            :loading="loading"
                            @click="nextStep()"
                        >
                            Continuar
                            <v-icon right>
                                mdi-arrow-right
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
    </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { authAPI } from "../services/services";
export default {
    name: "ConfirmacaoEmail",
    data() {
        return {
            errors: [],
            loading: false,
        };
    },
    validations: {
        token: {
            required,
        },
    },
    computed: {
        tokenTimer: {
            get() {
                return this.$store.getters["parsedTokenTimer"];
            },
            set(v) {
                this.$store.commit("startTokenTimer", v);
            },
        },
        token: {
            get() {
                return this.$store.getters["token"];
            },
            set(v) {
                this.$store.commit("token", v);
            },
        },
        tokenSent: {
            get() {
                return this.$store.getters["tokenSent"];
            },
            set(v) {
                this.$store.commit("tokenSent", v);
            },
        },
        tokenConfirmed: {
            get() {
                return this.$store.getters["tokenConfirmed"];
            },
            set(v) {
                this.$store.commit("tokenConfirmed", v);
            },
        },
        resendingToken: {
            get() {
                return this.$store.getters["resendingToken"];
            },
            set(v) {
                this.$store.commit("resendingToken", v);
            },
        },
        email: {
            get() {
                return this.$store.getters["usuario/email"];
            },
        },
    },
    watch: {
        token() {
            this.errors = [];
            this.$v.token.required ? null : this.errors.push("Campo obrigatório");
        },
    },
    async created() {
        if (this.tokenSent) return;
        this.loading = true;
        try {
            const response = await authAPI.generateRegistrationToken({
                email: this.email,
            });
            if (response.status !== 200) {
                this.setSnackbarMessage(
                    "Houve algum erro ao enviar seu Token, tente novamente mais tarde",
                    4000
                );
            } else if (response.data.success === false) {
                this.setSnackbarMessage(response.data.msg, 4000);
            } else if (response.data.success === true) {
                this.tokenSent = true;
            }
        } catch (error) {
            console.log(error.response.msg);
        } finally {
            this.loading = false;
        }
    },
    methods: {
        returnStep() {
            return this.$store.commit("returnStep");
        },
        async nextStep() {
            await this.confirmToken();
            if (!this.tokenConfirmed) return;

            this.$store.commit("nextStep");
        },
        async confirmToken() {
            this.loading = true;
            if (this.tokenConfirmed) return;
            try {
                const response = await authAPI.sendRegistrationToken({
                    email: this.email,
                    token: this.token,
                });
                if (response.status !== 200)
                    throw Error("Houve um erro em autenticar o token");
                if (response.data.success !== true) throw Error("Token inválido");
                this.tokenConfirmed = true;
            } catch (error) {
                this.errors.push(error.message);
                this.clearToken();
                this.tokenConfirmed = false;
            } finally {
                this.loading = false;
            }
        },
        async regenerateToken() {
            if (this.tokenTimer !== "0:00" || this.resendingToken) return;
            this.resendingToken = true;
            this.clearToken();

            try {
                const response = await authAPI.generateRegistrationToken({
                    email: this.email,
                });
                if (response.status !== 200 || response.data.success !== true) {
                    throw Error("Houve um erro em reenviar o token");
                }
                this.$store.commit("startTokenTimer");
            } catch (error) {
                this.setSnackbarMessage(error.message);
                this.setResendTokenTimer();
            } finally {
                setTimeout(() => {
                    this.resendingToken = false;
                }, 200);
            }
        },
        setSnackbarMessage(msg, timeout = 3000) {
            this.$store.commit("updateSnackbarMessage", {
                message: msg,
                timeout,
            });
        },
        clearToken() {
            this.tokenConfirmed = false;
            this.confirmationToken = "";
        },
        setResendTokenTimer(timer = 60*5) {
            this.$store.commit("startTokenTimer", timer);
        },
    },
};
</script>

<style lang="scss" scoped>
#re-enviar {
  text-transform: none !important;
}
.confirmacao-email {
  max-width: 420px;
}
</style>
